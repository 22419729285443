import api from "@/services/api";
//import store from "@/store";

export default {
    // Get a list of the Campaigns
    getCampaigns() {
        return api().get("BroadSignAPI/GetCampaigns");
    },
    getFlightPackageURL(fid) {
        return api().get("Airport/GetFlightPackageURL/" + fid);
    },
    getSchedules(fid) {
        return api().get("BroadSignAPI/GetSchedules");
    },
    getContent() {
        return api().get("BroadSignAPI/GetContent");
    },
    postToSchedule(data) {
        //return api().post("BroadSignAPI/PostSchedule", data)
/*        return fetch(process.env.VUE_APP_API_URL+'BroadSignAPI/PostSchedule', {
            method: 'POST', // or 'PUT'
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + store.getters['User/token'],
            },
            body: data
          }); */
        const headers = {
            'Content-Type': 'application/json'
        }
        return api().post("BroadSignAPI/PostSchedule", data, {
            headers: headers
        })
    },
    removeContent(data) {
        return api().post("BroadSignAPI/RemoveContent", data)
    },
    removeContentFromSchedule(data) {
        return api().post("BroadSignAPI/RemoveContentFromSchedule", data)
    },
};