<template>
    <v-container>
        <!---v-row class="d-flex flex-column align-center"> -->
        <v-row>
            <v-col cols="12" class="d-flex flex-column align-center">
                <h1 class="ma-n12 pa-n12">DOOH Broadsign Testing Screen Details</h1>
            </v-col>
            <br/>
            <br/>
            <br/>
            <br/>
            <v-col cols="6" class="d-flex flex-column align-center">
                    <h2 class="mt-n12">Broadsign Schedules</h2>
            </v-col>
            <v-col cols="6" class="d-flex flex-column align-center">
                    <h2 class="mt-n12">Content Info</h2>
            </v-col>
            <v-col keys="1" cols="6">
                <div class="my-2">
                    <v-btn
                        color="error"
                        dark
                        large
                        @click="unschedule()"
                    >
                        <v-icon left large>mdi-delete</v-icon>
                        Unschedule
                    </v-btn>
                </div>
                <br v-show="scheduleLoading" />
                <v-skeleton-loader
                    v-for="i in 5"
                    v-bind:key="index"
                    v-if="scheduleLoading"
                    class="mx-auto"
                    type="card"
                ></v-skeleton-loader>
                <div v-for="index in playerNumber" :key="index">
                    <v-card class="mx-auto" :loading="unscheduleLoading">
                        <v-list>
                            <v-subheader>{{playerNames[index-1]}}</v-subheader>
                            <v-list-item-group
                                v-model="selectedContentFromSchedule[index-1]"
                                multiple
                                active-class=""
                            >
                                <v-list-item v-for="content in broadsignSchedules[index-1]" v-bind:key="index" v-if="content.active">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                     <v-checkbox :input-value="active"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="content.name"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </div>
            </v-col>
            <v-col cols="6">
                <div class="my-2">
                    <v-btn
                        color="error"
                        dark
                        large
                        @click="deleteContent()"
                    >
                        <v-icon left large>mdi-delete</v-icon>
                        Delete Content
                    </v-btn>
                </div>
                <br v-show="contentLoading"/>
                <v-skeleton-loader
                    v-if="contentLoading"
                    class="mx-auto"
                    type="card"
                ></v-skeleton-loader>
                <v-card class="mx-auto" :loading="deleteLoading">
                    <v-list>
                    <v-list-item-group
                                v-model="selectedContentToDelete"
                                multiple
                                active-class=""
                        >
                    <v-list-item v-for="index in contentList.length" :key="index">
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content @click="(contentShow[index-1] = !contentShow[index-1])">
                                        <v-list-item-title
                                            v-text="contentList[index-1].name"
                                            >
                                        </v-list-item-title>
                                        <!--- --->
                                        <v-divider></v-divider>
                                    {{contentList[index-1].feeds}}
                            </v-list-item-content>
                        </template>
                        </v-list-item>
                    </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BroadsignController from '@/services/controllers/Broadsign'
import axios from 'axios'

export default {
    data: () => ({
        broadsignSchedules: [],
        selectedContentFromSchedule: [],
        playerNumber: 0,
        playerNames: [],
        scheduleLoading: true,
        unscheduleLoading: false,

        contentLoading: true,
        deleteLoading: false,
        selectedContentToDelete: [],
        contentList: [],
        contentShow: [],
    }),

    computed: {
    },

    async created () {
      await this.getBroadsignSchedules();
      await this.getContentList();
    },

    methods: {
        async getBroadsignSchedules() {
            const {data = null} = await BroadsignController.getSchedules()
            /*
            axios.get("http://localhost:5002/api/BroadSignAPI/GetSchedules")
            .then((res) => {
                //console.log(res.data);
                this.broadsignSchedules = res.data[0];
                this.playerNames = res.data[1];
                this.playerNumber = this.playerNames.length;
                this.selectedContentFromSchedule = Array.from(Array(res.data[1].length), () => []);
                console.log(res.data[0]);
                this.scheduleLoading = false;
            })
            */
            .catch((err) => {
                console.log(err);
            });

            this.broadsignSchedules = data[0];
            this.playerNames = data[1];
            this.playerNumber = this.playerNames.length;
            this.selectedContentFromSchedule = Array.from(Array(data[1].length), () => []);
            //console.log(data[0]);
            this.scheduleLoading = false;
        },

        async deleteContent() {
            this.deleteLoading = true;
            //console.log(this.selectedContentToDelete);
            let tmp = this.selectedContentToDelete.map((element) => {
                return {
                    id: this.contentList[element].id,
                    name: this.contentList[element].name,
                };
            });
            const res = await BroadsignController.removeContent(tmp)
            .catch((err) => {
                this.$root.$emit("snackbarError", 
                    "Failed to delete content try again: "+err.response.data.message);
                console.log(err);
            });
//            let res = await axios.post('http://localhost:5002/api/BroadSignAPI/RemoveContent', tmp);
            //console.log(res);
            await this.getBroadsignSchedules();
            this.selectedContentToDelete = [];
            await this.getContentList();
            this.deleteLoading = false;
            
            this.$root.$emit("snackbarSuccess", "Deleted Content");
        },

        async getContentList() {
//            let res = await axios.get("http://localhost:5002/api/BroadSignAPI/GetContent");
            const {data = null} = await BroadsignController.getContent()
            .catch((err) => {
                console.log(err);
            });
            //console.log("getContentList: "+data);
            this.contentList = data;
            this.contentLoading = false;
            this.contentShow = new Array(this.contentList.length). fill(false);
        },

        async unschedule() {
            this.unscheduleLoading = true;
            //console.log("Selected: "+this.selectedContentFromSchedule);
            var unscheduleList = this.selectedContentFromSchedule.map((e,i) =>
                e.map((element,j) => {
                    return {
                        "name": this.broadsignSchedules[i][element].name,
                        "id": this.broadsignSchedules[i][element].id,
                        "player_name": this.playerNames[i],
                    };
                }));
            //console.log([].concat(...unscheduleList));

            let res = await BroadsignController.removeContentFromSchedule([].concat(...unscheduleList))
            .catch((err) => {
                console.log(err);
                this.$root.$emit("snackbarError", 
                    "Failed to unschedule content try again: "+err.response.data.message);
            });
            //console.log("removeContentFromSchedule response:"+res);
            await this.getBroadsignSchedules();
            this.unscheduleLoading = false;
            this.$root.$emit("snackbarSuccess", "Unscheduling Content Done");
        },
    },
}
</script>
